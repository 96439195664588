import { useEffect, useState } from 'react';

/**
 * A React hook to run a CSS media query
 *
 * Returns true when the media query matches.
 */
export const useMediaQuery = (query: string) => {
    const [isMatch, setIsMatch] = useState(false);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(query);
        setIsMatch(mediaQueryList.matches);

        const listener = (e: MediaQueryListEvent) => {
            setIsMatch(e.matches);
        };

        mediaQueryList.addEventListener('change', listener);
        return () => mediaQueryList.removeEventListener('change', listener);
    }, [query]);

    return isMatch;
};

/**
 * Predefined media queries to match Bootstrap breakpoints.
 */
export const useIsBreakpointXs = () => !useIsBreakpointSm();
export const useIsBreakpointSm = () => useMediaQuery('(min-width: 576px)');
export const useIsBreakpointMd = () => useMediaQuery('(min-width: 768px)');
export const useIsBreakpointLg = () => useMediaQuery('(min-width: 992px)');

/**
 * Predefined media query to match dark mode.
 */
export const useIsDarkMode = () => useMediaQuery('(prefers-color-scheme: dark)');
