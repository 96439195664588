export const siteURL = 'https://ente.io';
const url = siteURL;

const twitterText = `Use @enteio to store your #photos securely, and share them only with the people you want. No ads, no tracking.

Take back control of your #privacy now, but do it with style 😎

Start today, at ${url}`;

const emailSubject = 'Ente Photos';
const emailBody = `Check out ${url} for storing and sharing photos`;

export const twitterLink = (() => {
    const encodedText = encodeURIComponent(twitterText);
    return `https://twitter.com/intent/tweet?text=${encodedText}`;
})();

export const emailLink = (() => {
    return `mailto:?subject=${emailSubject}&body=${emailBody}`;
})();

export const alternativeToLink =
    'https://alternativeto.net/software/ente/about/';
